<template>
    <div class="agreement-box">
        <div class="paragraph-box">
            <p>本协议由以下协议缔约方在中华人民共和国宁波市签署：</p>
            <p>甲方：""</p>
            <p>乙方：宁波顺欧供应链管理有限公司</p>
            <p>为了实现在航运物流协同服务上的合作，甲乙双方在平等协商的基础上，就合作事宜展开交流洽谈。基于双方优势互补、合作共赢的愿望，依照《中华人民共和国合同法》及有关法规，甲乙双方本着友好合作的原则，经协商签订本合同，并由双方共同恪守。</p>
        </div>
        <div class="paragraph-box">
            <h3>一、总则</h3>
            <p>1. 甲方同意接受乙方的技术标准和技术条件，使用乙方提供的预配舱单发送平台进行上海预配舱单数据传输服务。</p>
            <p>2. 甲乙双方为明确权利和义务、规范双方的操作行为,在平等互利，协商一致的原则下， 就甲方采用包括且不限于网页录入、电子数据上载等形式，委托乙方向船代传输甲方提运 单证的出口舱单数据，并自愿达成本协议。双方履行本协议事项中，有未予以约定事项的， 另以附件补充约定。</p>
            <p>3. 甲方通过登录乙方网上服务系统（登录网址  http://logistics.ceecexpo.com/）上传甲方的出口舱单等数据的行为，表明甲方接受乙方所提供的网上服务之形式委托乙方传输相关 出口数据。甲方并愿意承担本协议约定的义务。</p>
            <p>4. 乙方同意甲方使用乙方网上服务系统传输相关出口数据，乙方仅作为数据传输平台角色提供服务，并不构成乙方作为承运人或其代理人之身份接受甲方之订舱行为，从而也并不因本协议委托事项构成双方间之运输合同关系。</p>
        </div>
        <div class="paragraph-box">
            <h3>二、甲方权利与义务</h3>
            <p>1. 甲方收到乙方提供的用户名和初始密码后，应立即对初始密码进行修改。</p>
            <p>2. 甲方应妥善保管用户名和初始密码以及修改后的密码，不得公开或告知他人（包括 但不限于第三人、甲方内部未经授权的员工、乙方的员工等）。如甲方知悉用户名和初始密码己经失密或可能已经失密时，应当立即通知乙方及有关各方，并终止该用户名和密码的使用。</p>
            <p>3. 甲方使用乙方提供的管理权限功能，表示甲方完全认同乙方提供的权限管理功能。对以甲方用户名显示的电子委托或其它网上操作，即在线委托或电子报文一经发出即视同为甲方自主做出的委托，甲方确认为甲方本身的行为。</p>
            <p>4. 甲方应确保上传的出口舱单等数据符合法律、法规、各部门规章及海关相关公告等的规定。数据内不得包含不真实的、非法性、或与其他出口单据不一致的内容。</p>
            <p>5. 甲方应当遵守乙方在其官网及网上服务系统上发布的操作指导文件。不得非法使用 乙方的网上服务系统。</p>
            <p>6. 甲方有责任防止乙方网上服务系统受到非法侵入、非法传输及遭受其他可能影响到 正常运行的妨碍，并保护业务记录和数据信息不受非法侵入、篡改或毁损。</p>
            <p>7. 甲方不得发送与舱单数据无关的报文，如非法文件木马程序病毒程序等。</p>
            <p>8. 甲方不得利用乙方服务系统中的漏洞、故障进行不正当的操作。</p>
            <p>9. 甲方应主动检查和确认业务进展状态，如有遗漏、延误，应及时联系乙方。所有 EDI报文传送成功与否，应以接收方回馈信息为准，甲方如查询不到回馈信息，应及时与 乙方及接收方联系。</p>
        </div>
        <div class="paragraph-box">
            <h3>三、乙方的权利义务</h3>
            <p>1. 甲方委托乙方代为传输出口舱单及相关数据的，乙方有权依据该项服务向甲方收取 相关费用。</p>
            <p>2. 乙方有权维护自身网络系统的安全，并在必要的情况下要求甲方对其电子数据签名 予以认证。</p>
            <p>3. 乙方有义务保存互往的数据电文、传输记录和接收回执等。若甲方未保存上述电子 文件，则以乙方保存的电文为准。</p>
            <p>4. 乙方有权拒绝接收、处理、存储、交换和传输不按乙方要求格式的数据电文。但乙 方对甲方要求委托传输相关数据的内容没有审核的义务。</p>
            <p>5. 乙方发现甲方有违背安全、非法使用或非法传输行为，有权暂停数据电文交换并中 止本协议。</p>
            <p>6. 乙方应保证网上服务系统和网络设备正常工作。如发生故障，乙方应及时通过网站 发布相关信息告知甲方，并在故障排除后再次通过网站发布相关信息来通知甲方。故障发 生期间双方以传统的委托方式进行委托业务和信息传递。</p>
            <p>7. 乙方应根据口岸政策环境变化，对网上服务系统进行调整，以适应口岸行政机关要 求的操作模式。</p>
            <p>8. 乙方为甲方传输电子数据而产生的通讯费及所需的设备，由乙方自行承担。</p>
            <p>9. 乙方为适应甲方需求并接受其委托而自行开发或委托第三方开发软件系统而产生的 费用由乙方自行承担。</p>
        </div>
        <div class="paragraph-box">
            <h3>四、计费标准和结算方式</h3>
            <b>计费表：</b>
            <table>
                <thead>
                    <tr>
                        <th>模式</th>
                        <th>原始数据价格</th>
                        <th>修改报文价格</th>
                        <th>删除报文价格</th>
                        <th>备注</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>舱单代发模式</td>
                        <td>人民币30元/ 票</td>
                        <td>人民币50元/票</td>
                        <td>人民币 50元/票</td>
                        <td>此费包含乙方平台数据传输服务费，不包含其他任何额外产生的费用。如有后续代收代付费用产生，包括但不限于亿通平台数据服务费、船代通道费、船代人工操作费、船代罚金、海关罚金等，由甲方自行承担。针对未来第三方收费，如船代通道费，双方需再次签订补充协议约定。</td>
                    </tr>
                </tbody>
            </table>
            <h4>1.核算标准:项目、票量核算以及收费标准</h4>
            <p>1.1 项目</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;预配舱单代发服务。</p>
            <p>1.2 票量核算</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;统计原则：以单个关单号为统计单位，一次发送成功计算一票业务量。</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;核算流程：乙方每月 5日前提供前一月乙方费用账单，供乙方核对。如发现账单有误，乙方应提供依据，经甲方确认后予以更正。乙方应在每月15日前付清上月甲方所开账单的所有费用。甲方在收到乙方付款后 7 个工作日内，开具发票给乙方。</p>
            <h4>2. 收费方式</h4>
            <p>乙方应在每月15日前付清上月甲方所开账单的所有费用,账单中，按以上《计费分成表》计算并包含利润分成数据。甲方收到付款 7 个工作日内向乙方提供对应费用的、税务部门认可的全额增值税专用发票。乙方一律按银行转账向甲方支付费用，不得现金支付。</p>
            <h4>3. 赔偿责任</h4>
            <p>因乙方平台转发数据时产生的错误而造成的对客户的赔偿责任由乙方承担；</p>
            <p>因甲方产生的数据错误而造成的对客户的赔偿责任由甲方承担；</p>
            <p>因不可判断之原因造成的数据传输错误而造成的对客户的赔偿责任由双方协商解决；</p>
        </div>

        <div class="paragraph-box">
            <h3>五、保密条款</h3>
            <h4>1. 保密信息</h4>
            <p>就合作协议所涉及内容，双方均承诺并保证不向第三方泄露本协议信息以及履行协议过程中涉及到保密信息。该保密信息指由一方向对方透露的，与双方合作有关的任何口头或书面形式或其他形式的不公开信息，包括但不限于商业计划、客户名单、客户数据、订单数据、报价与价格、技术数据、产品构思、开发计划、职员名单、操作手册、加工工艺、技术理论、发明创造、财务情况和其他递交时约定为保密信息的资料（以下通称“保密信息”）。本协议中保密信息还特别指：客户单位或者个人信息、业务信息，即除正常业务开展即为满足客户的服务需求以外，双方均不得向第三方提供上述该等信息，该信息仅用于该次操作，如有客户投诉，双方有权单方终止合同，包括但不限于保留追加违约方法律责任的权利。</p>
            
            <h4>2. 保密期限</h4>
            <p>本协议所涉及保密信息保密期限为永久保密。本协议合作期限内及本协议终止或者解除后，甲、乙双方承诺不向任何第三方（包括但不限于与对方构成商业竞争关系的企业、商业机构或者组织）提供有关双方业务、技术等一切相关信息或者资料，否则由泄密一方承担相应的责任，并赔偿另一方因此造成的一切损失。</p>
            <h4>一. 违约责任及损害赔偿</h4>
            <p>1. 甲乙双方中的任何一方违反在本协议中所做出的保证、承诺或其他条款，均构成违约。违约方应向守约方赔偿由违约产生的相关损失，若因此产生诉讼的，应当赔偿守约方由此遭受的律师费、诉讼费、鉴定费等损失。</p>
            <p>2. 除本协议特别约定外，协议双方不得单方面擅自变更或终止本协议。</p>
            
            <h4>二. 免责条款</h4>
            <p>1. 鉴于互联网所具有之特殊性质，乙方对黑客攻击、网络病毒、银行掉单、电信部门技术调整及线路故障、银行政策调整导致之影响、因政府管制而造成的暂时性关闭、电力系统故障或限制性供电等任何影响网络正常经营之情形不承担责任。</p>
            <p>2. 任何一方由于战争及严重的火灾、台风、地震、水灾和其它不能预见、不可避免和不能克服的事件而影响其履行本合同所规定的义务的，受事故影响的一方应将发生的不可抗力事故的情况以书面形式通知另一方。</p>
            <p>3. 双方在不可抗力事故停止后或影响消除后立即继续履行合同义务，合同有效期和/或有关履行合同的预定的期限相应延长。</p>
            <p>4. 因不可抗力或者其他意外事件，使得本协议的履行不可能、不必要或者无意义的，双方可以协商解除本协议。</p>
            <p>5. 除上述责任与免责条款，乙方同时还享有以下权利：乙方上游合作商在与乙方签订的合作协议和/或具体合同中，所享有的有限责任与免责条款，将等同地适用于甲乙双方合作关系中的甲方。甲方有权知晓上述合作协议中有限责任与免责条款的内容。</p>
            
            <h4>三. 争议解决方式</h4>
            <p>1. 凡因订立、解释、履行本协议所发生的或与本协议有关的一切争议，双方应通过友好协商解决。当事人不愿协商解决或者协商不成，双方决定交由乙方住所地法院诉讼解决。</p>
            <p>2. 争议的解决适用中华人民共和国法律、法规、条例和计算机行业惯例。</p>
            <p>3. 除正在仲裁或诉讼的争议事项外，双方应继续行使其本协议项下的其他权利，并履行其本协议项下的其他义务。</p>
            
            <h4>四. 合同的变更、续定和终止</h4>
            <p>1. 合同的调整或变更均须由甲、乙双方签署书面的合同修改书，由双方授权代表签字并加盖合同章后视为有效。在协议有效期限内，若有新的需求，应以补充合同的形式签订，补充合同与本合同具有同等的法律效力。</p>
            <p>2. 在合同有效期限内，双方均应严格遵守合同文本中的各项条款，不得无故终止合同，如因特殊原因终止合同，需提前6个月书面通知对方，双方友好协商解决。否则将由合同终止方承担相应的法律责任及由此造成对方的相关经济损失。</p>
            <p>3. 本合同有效期截止至2022 年12 月31日，本合同期满时，合同项下的任何未了的债权债务不受合同期满的影响。</p>

            <h4>五. 其他</h4>
            <p>1. 本合同附件或补充协议均视为合同的组成部分，与本合同具有同等法律效力。</p>
            <p>2. 本合同一式两份，甲方执两份，乙方执壹份，具有同等法律效力。</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "Preissued_manifest",
    data() {
        return {}
    }
}
</script>

<style lang="scss" scoped>
table{
    width: 99%;
    border-collapse: collapse;
}

th,td{
    border: 1px solid #999;
    text-align: center;
    padding: 20px 0;
}

table thead tr{
    background-color: #008c8c;
    color: #fff;
}

table tbody tr td:nth-child(2){
    width: 100px;
}

table tbody tr td:nth-child(3){
    width: 100px;
}

table tbody tr td:nth-child(4){
    width: 100px;
}

table tbody tr td:first-child{
    width: 100px;
}

table tfoot tr td{
    text-align: right;
    padding-right: 20px;
}

.agreement-box{
    height: 700px;
    overflow-y: auto;
}
.paragraph-box{
    margin-bottom: 20px;
    h3{
        margin-bottom: 10px;
    }
    h4{
        margin-top: 5px;
        margin-bottom: 5px;
    }
    p{
        font-size: 14px;
        color: #333;
        text-indent: 1em;
    }
}
</style>