<template>
    <div class="box">
        <div class="airTransport">
            <!-- 海运询柜 -->
            <div class="airTransport_Top">
                <div class="backgroundPicture"></div>
            </div>
        </div>
        <div>
            <Trailer_Component></Trailer_Component>
        </div>
        <Footer_Compontent></Footer_Compontent>
    </div>
</template>

<script>
import Trailer_Component from '../../components/Trailer/Trailer_Component.vue';
import Footer_Compontent from '../../components/Footer_Compontent.vue';
export default {
    name: "Trailer",
    components: { Trailer_Component, Footer_Compontent }
}
</script>

<style lang="scss" scoped>
.box {
    width: 100%;
    height: 100%;
}

.airTransport {
    width: 100%;

    .airTransport_Top {
        width: 100%;
        margin: 0 auto;

        // height: 363px;
        .backgroundPicture {
            height: 158px;
            background: url('../../assets/homePage/tielu.png') no-repeat;
            background-size: 100% 100%;
        }
    }
}


</style>