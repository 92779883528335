<template>
    <div class="TrailerBox">
        <div class="TrailerTitle"><span>拖车</span></div>
        <div class="TrailerContentBox">
            <!-- 头部输入框 -->
            <div class="contentHeaderInputs">
                <div class="headerInputList1 iptList">
                    <div class="headerInputItem">
                        <span>*</span>
                        <span>输入港口</span>
                        <div class="ipt">
                            <!-- <el-input v-model="importPortValue" placeholder="输入港口"></el-input> -->
                            <el-select v-model="importPortValue" placeholder="请选择港口">
                                <el-option v-for="item in portList" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="headerInputItem">
                        <span>*</span>
                        <span>主提单号</span>
                        <div class="ipt">
                            <el-input v-model="mainBillNoValue" placeholder="主提单号"></el-input>
                        </div>
                    </div>
                    <div class="headerInputItem">
                        <span></span>
                        <span>船公司</span>
                        <div class="ipt">
                            <!-- <el-select v-model="shippingCompany.shippingCompanyValue" placeholder="船公司">
                                <el-option v-for="item in shippingCompany.shippingCompanyList" :key="item.value"
                                    :label="item.label" :value="item.value">
                                </el-option>
                            </el-select> -->
                            <el-input v-model="shippingCompany.shippingCompanyValue" placeholder="船公司"></el-input>
                        </div>
                    </div>
                    <div class="headerInputItem">
                        <span>*</span>
                        <span>船名</span>
                        <div class="ipt">
                            <el-input v-model="shipNameValue" placeholder="船名"></el-input>
                        </div>
                    </div>
                    <div class="headerInputItem">
                        <span>*</span>
                        <span>航次</span>
                        <div class="ipt">
                            <el-input v-model="voyageNumberValue" placeholder="航次"></el-input>
                        </div>
                    </div>
                </div>
                <div class="headerInputList2 iptList">
                    <div class="headerInputItem">
                        <span></span>
                        <span>港区</span>
                        <div class="ipt">
                            <!-- <el-select v-model="portArea.portAreaValue" placeholder="港区">
                                <el-option v-for="item in portArea.portAreaList" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select> -->
                            <el-input v-model="portArea.portAreaValue" placeholder="港区"></el-input>
                        </div>
                    </div>
                    <div class="headerInputItem">
                        <span></span>
                        <span>中转港</span>
                        <div class="ipt">
                            <el-input v-model="transitPortValue" placeholder="中转港"></el-input>
                        </div>
                    </div>
                    <div class="headerInputItem">
                        <span>*</span>
                        <span>目的港</span>
                        <div class="ipt">
                            <el-input v-model="portOfDestinationValue" placeholder="目的港"></el-input>
                        </div>
                    </div>
                    <div class="headerInputItem">
                        <span></span>
                        <span>业务编号</span>
                        <div class="ipt">
                            <el-input v-model="businessNoValue" placeholder="业务编号"></el-input>
                        </div>
                    </div>
                    <div class="headerInputItem">
                        <span></span>
                        <span>ETD</span>
                        <div class="ipt">
                            <!-- <el-input v-model="etdValue" placeholder="ETD"></el-input> -->
                            <el-date-picker v-model="etdValue" type="date" placeholder="预计到达时间" value-format="yyyy-MM-dd">
                            </el-date-picker>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 联系地址··· -->
            <div class="contactAddress ">
                <!-- 门点地址 -->
                <div class="gatePointAddress flexibleLayouts">
                    <span>*</span>
                    <span>门点地址</span>
                    <div class="ipts sflexibleLayouts">
                        <el-input v-model="gatePointAddressValue" placeholder="请输入内容"></el-input>
                        <!-- <div class="img">
                            <img src="../../assets/BookingSpace/Location.png" alt="">
                        </div> -->
                    </div>
                </div>
                <!-- 工厂联系人 -->
                <div class="factoryContact flexibleLayouts">
                    <div class="factoryContactItem flexibleLayouts">
                        <span>*</span>
                        <span>工厂联系人</span>
                        <div class="ipt">
                            <el-input v-model="factoryContactValue" placeholder="请输入发货人姓名"></el-input>
                        </div>
                    </div>
                    <div class="factoryContactItem flexibleLayouts">
                        <span>*</span>
                        <span>联系人电话</span>
                        <div class="ipt">
                            <el-input v-model="contactPhoneValue" placeholder="请输入手机号/固话"></el-input>
                        </div>
                    </div>
                    <div class="factoryContactItem flexibleLayouts">
                        <span>*</span>
                        <span>工厂是否禁区</span>
                        <div class="radios">
                            <el-radio v-model="doorpointRadio" label="0">否</el-radio>
                            <el-radio v-model="doorpointRadio" label="1">是</el-radio>
                        </div>
                        <div class="ipt" v-show="doorpointRadio == 1">
                            <el-input v-model="peerApproachValue" placeholder="通行办法"></el-input>
                        </div>
                    </div>
                </div>
                <!-- 是否位于保税区内 -->
                <div class="bondedArea flexibleLayouts">
                    <div class="bondedAreaConent">
                        <span class="asterisk">*</span>
                        <span>是否位于保税区内</span>
                        <el-radio v-model="bondedAreaRadio" label="0">否</el-radio>
                        <el-radio v-model="bondedAreaRadio" label="1">是</el-radio>
                    </div>
                    <div class="keepOnRecord">
                        <span class="asterisk">*</span>
                        <span>是否需要备案</span>
                        <el-radio v-model="keepOnRecordRadio" label="0">否</el-radio>
                        <el-radio v-model="keepOnRecordRadio" label="1">是</el-radio>
                    </div>
                </div>
                <!-- 件毛体数据提供方式 -->
                <div class="pieceTrichome">
                    <span class="asterisk">*</span>
                    <span>件毛体数据提供方式</span>
                    <el-radio v-model="pieceTrichomeRadio" label="0">固定门点提供</el-radio>
                    <el-radio v-model="pieceTrichomeRadio" label="1">固定我们提供</el-radio>
                    <el-radio v-model="pieceTrichomeRadio" label="2">不固定，我们或者门点提供</el-radio>
                </div>
                <div class="pieceTrichome" v-show="pieceTrichomeRadio == 2">
                    <span class="asterisk">*</span>
                    <span>本次件毛体数据将由</span>
                    <el-radio v-model="unfixedRadio" label="1">门点提供</el-radio>
                    <el-radio v-model="unfixedRadio" label="2">我们提供</el-radio>
                </div>

            </div>
            <!-- 表格  -->
            <div class="tabs">
                <div class="table">
                    <el-table :data="pullcartExtList" style="width: 100%" :header-cell-style="{
                        background: '#F4F4F4'
                    }">
                        <el-table-column fixed type="index" label="序号" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="box_making_time" label="做箱时间" width="150">
                        </el-table-column>
                        <el-table-column prop="bill_no" label="提单号" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="box_type" label="箱型" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="kgs" label="毛重(kg)" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="dayton" label="预计货重(吨)" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="pkg" label="件数" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="cbm" label="体积(m³)" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="product_name" label="品名" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="is_white_card" label="是否为白卡" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="withholding" label="预提" width="150">
                        </el-table-column>
                        <el-table-column prop="house_awb" label="副提单" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column fixed="right" label="操作" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <el-button @click="handleSee(scope.$index, scope.row)" type="text" size="small">查看
                                </el-button>
                                <el-button type="text" size="small" @click="handleDelete(scope.$index, pullcartExtList)">
                                    删除
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="addPackingPlan" @click="handleOrderDetails">
                    <span>添加做箱计划</span>
                </div>
                <div class="inputBox">
                    <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" placeholder="做箱要求（选填）"
                        v-model="boxMakingRequirements">
                    </el-input>
                </div>
            </div>
            <!-- 是否已放设备单 -->
            <div style="margin-top: 40px;padding: 0 24px;">
                <!-- 是否已放设备单 -->
                <div class="equipmentList flexibleLayouts">
                    <div class="whether">
                        <span class="asterisk">*</span>
                        <span>是否已放设备单</span>
                        <el-radio v-model="placedRadio" label="0">否</el-radio>
                        <el-radio v-model="placedRadio" label="1">是</el-radio>
                    </div>
                    <div class="notice" v-show="placedRadio == 0">
                        <div>
                            <span class="asterisk">*</span>
                            <span>等通知放设备单</span>
                            <el-radio v-model="noticeRadio" label="0">否</el-radio>
                            <el-radio v-model="noticeRadio" label="1">是</el-radio>
                        </div>
                    </div>
                </div>
                <!-- 做箱时间是否等开港/通知 -->
                <div class="equipmentList flexibleLayouts" style="margin-top: 32px;">
                    <div class="whether">
                        <span class="asterisk">*</span>
                        <span>做箱时间是否等开港/通知</span>
                        <el-radio v-model="boxMakingTimeRadio" label="0">否(按约定时间做箱)</el-radio>
                        <el-radio v-model="boxMakingTimeRadio" label="1">等通知做箱</el-radio>
                        <el-radio v-model="boxMakingTimeRadio" label="2">等开港做箱</el-radio>
                    </div>
                </div>
                <!-- <div class="equipmentList flexibleLayouts" style="margin-top: 32px;" v-show="boxMakingTimeRadio == 1">
                    <div class="whether">
                        <span class="asterisk">*</span>
                        <span style="opacity: 0;">做箱时间是否等开港/通知</span>
                        <el-radio v-model="boxMakingTimeTrueRadio" label="0">等通知做箱</el-radio>
                        <el-radio v-model="boxMakingTimeTrueRadio" label="1">等开港做箱</el-radio>
                    </div>
                </div> -->
                <!-- 截单时间 -->
                <div class="cutOffTime flexibleLayouts">
                    <div class="cutOffTimeInputBox flexibleLayouts">
                        <span class="asterisk">*</span>
                        <span>截单时间</span>
                        <div class="selectDate">
                            <el-date-picker v-model="selectDateValue" type="date" placeholder="请选择日期" prefix-icon="none"
                                value-format="yyyy-MM-dd">
                            </el-date-picker>
                        </div>
                        <div class="selectTime">
                            <el-time-select v-model="selectTimeValue" :picker-options="{
                                start: '00:00',
                                step: '00:30',
                                end: '24:00'
                            }" placeholder="选择时间" value-format="HH:mm:ss AA">
                            </el-time-select>
                        </div>
                    </div>
                    <div class="dataTruncation">
                        <span class="asterisk">*</span>
                        <span>是否出数据截单</span>
                        <el-radio v-model="dataTruncationRadio" label="0">否</el-radio>
                        <el-radio v-model="dataTruncationRadio" label="1">是</el-radio>
                    </div>
                </div>
                <!-- SOC箱 -->
                <div class="socBox">
                    <span class="asterisk">*</span>
                    <span>SOC箱</span>
                    <el-radio v-model="socBoxRadio" label="0">否</el-radio>
                    <el-radio v-model="socBoxRadio" label="1">是</el-radio>
                </div>
                <!-- 是否需要过磅 -->
                <div class="weigh">
                    <span class="asterisk">*</span>
                    <span>是否需要过磅</span>
                    <el-radio v-model="weighRadio" label="0">否</el-radio>
                    <el-radio v-model="weighRadio" label="1">是</el-radio>
                </div>
            </div>

            <!-- 添加装箱附件`` -->
            <div class="packingAccessories">
                <div class="title" @click="handleAddFil">
                    <span></span>
                    <el-upload class="upload-demo" :action="baseUrl + '/file/upload'" :headers="headerObj"
                        :on-preview="handlePreview" :on-success="handleAvatarSuccess1" :on-remove="handleRemove" :limit="1"
                        :on-exceed="handleExceed">
                        <div class="authenTitle">添加装箱附件</div>
                    </el-upload>
                </div>
                <!-- 委托舱单代发 -->
                <div class="delegationOrder">
                    <div class="delegationOrderTop">
                        <span class="asterisk">*</span>
                        <span>委托舱单代发(上海港)</span>
                        <el-radio v-model="delegationOrderRadio" label="0">否</el-radio>
                        <el-radio v-model="delegationOrderRadio" label="1">是</el-radio>
                        <span class="agreement" @click="showypcdBtn">《预配舱单传输合作协议》</span>
                    </div>
                    <div class="delegationOrderBott">
                        <p><span>首提单免费，次票仅需30元/票、最快五分钟回执、节假日无休 </span><span></span></p>
                        <p>
                            <span> 温馨提示：中远COSCO、地中海MSC、山东海丰SITC、以及部分小船司暂不提供舱单服务，详情请见 </span>
                        </p>
                    </div>
                </div>
                <!-- 委托报关 -->
                <div class="delegationOrder">
                    <div class="delegationOrderTop">
                        <span class="asterisk"></span>
                        <span>委托报关</span>
                        <el-radio v-model="entrustRadio" label="0">否</el-radio>
                        <el-radio v-model="entrustRadio" label="1">是</el-radio>
                        <span class="agreement" @click="showbgfwBtn">《报关服务协议》</span>
                    </div>
                    <div class="delegationOrderBott">
                        <p>
                            <span>每单报关80元（仅限委托拖车业务）</span>
                        </p>
                        <p>
                            <span> 需后续补齐报关单证资料，超过6个品名以6个品名为1组收1份联单费</span>
                        </p>
                    </div>
                </div>
                <!-- 检验监装 -->
                <div class="delegationOrder testRadios">
                    <div class="delegationOrderTop">
                        <span class="asterisk"></span>
                        <span>检验监装</span>
                        <el-radio v-model="testRadio" label="0">否</el-radio>
                        <!-- <el-radio v-model="entrustRadio" label="Y">是</el-radio> -->
                        <span class="agreement" @click="handlePrice">《价格明细》</span>
                        <span class="agreement" @click="showtyfwBtn">《通用服务条款》</span>
                    </div>
                    <div class="delegationOrderBott flexibleLayouts">
                        <span class="asterisk"></span>
                        <span>检验监装</span>
                        <el-radio v-model="testRadio" label="1">专业司机检验员（299起）</el-radio>
                        <div class="requirement flexibleLayouts" v-show="testRadio == 1">
                            <p>检验要求</p>
                            <div class="ipt">
                                <el-input v-model="testValue1" placeholder="请输入监装要求，如点数、抽样检查标签唛头、检视外包装、仅装箱拍照等">
                                </el-input>
                            </div>
                        </div>
                    </div>
                    <div class="delegationOrderBott flexibleLayouts">
                        <span class="asterisk"></span>
                        <span>检验监装</span>
                        <el-radio v-model="testRadio" label="2">委派检验员现场检验（379起）</el-radio>
                        <div class="requirement flexibleLayouts" v-show="testRadio == 2">
                            <p>检验要求</p>
                            <div class="ipt">
                                <el-input v-model="testValue2" placeholder="请输入监装要求，如点数、抽样检查标签唛头、检视外包装、仅装箱拍照等">
                                </el-input>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="placeOrder">
            <div class="preservation"><span>保存</span></div>
            <div class="submits" @click="handlePlaceOrder"><span>提交订单</span></div>
        </div>

        <!-- 价格 -->
        <div class="priceMask" v-show="priceMaskFlag">
            <div class="priceBox">
                <img src="../../assets/myHomePage/parceMask.png" alt="">
                <div class="maskClose" @click="handleClose">
                    <i class="el-icon-close"></i>
                </div>
            </div>
        </div>
        <orderDetails_Com v-if="flagOrderDetails" :handleFlagcancel="handleFlagcancel"
            :changeHandleDetermine="changeHandleDetermine" @emitDetailList="emitDetailList">
        </orderDetails_Com>

        <see_Com v-if="flagSeeOrder" :handleFlagSee="handleFlagSee" :changeHandleSee="changeHandleSee"
            :seeDetailList="seeDetailList" @emitModifyList="emitModifyList">
        </see_Com>

        <el-dialog title="《预配舱单传输合作协议》" :visible.sync="showDialogypcd" width="65%" top="1%" :before-close="handNOypcd">
           <Preissued_manifest/>
        </el-dialog>

        <el-dialog title="《报关服务协议》" :visible.sync="showDialogbgfw" width="60%" top="1%" :before-close="handNObgfw">
           <Customs_Declaration/>
        </el-dialog>

        <el-dialog title="《通用服务协议》" :visible.sync="showDialogtyfw" width="60%" top="1%" :before-close="handNOtyfw">
           <General_Service/>
        </el-dialog>
    </div>
</template>

<script>
import axios from 'axios';
import { baseUrl } from '../../tools/baseUrl';
import orderDetails_Com from './orderDetails_Com.vue';
import Preissued_manifest from './Preissued_manifest.vue';
import General_Service from './General_Service.vue';
import Customs_Declaration from './Customs_Declaration.vue';
import see_Com from './see_Component.vue';
export default {
    name: "Trailer_Component",
    components: { orderDetails_Com, see_Com, Preissued_manifest, General_Service, Customs_Declaration},
    data() {
        return {
            baseUrl,
            showDialogypcd:false,
            showDialogbgfw:false,
            showDialogtyfw:false,
            priceMaskFlag: false,
            importPortValue: "",//输入港口
            mainBillNoValue: "",//主提单号
            shippingCompany: { //船公司
                shippingCompanyValue: "",
                shippingCompanyList: [{
                    value: '选项1',
                    label: '黄金糕'
                }]
            },
            portList: [{
                value: "宁波港",
                label: "宁波港"
            }, {
                value: "上海港",
                label: "上海港"
            }, {
                value: "南京港",
                label: "南京港"
            }, {
                value: "连云港",
                label: "连云港"
            }, {
                value: "青岛港",
                label: "青岛港"
            }, {
                value: "天津港",
                label: "天津港"
            }, {
                value: "太仓港",
                label: "太仓港"
            }, {
                value: "乍浦港",
                label: "乍浦港"
            }, {
                value: "深圳港",
                label: "深圳港"
            }, {
                value: "蛇口",
                label: "蛇口"
            }, {
                value: "盐田",
                label: "盐田"
            }, {
                value: "张家港",
                label: "张家港"
            }, {
                value: "常熟港",
                label: "常熟港"
            }, {
                value: "芜湖港",
                label: "芜湖港"
            }, {
                value: "合肥港",
                label: "合肥港"
            }, {
                value: "南通港",
                label: "南通港"
            }, {
                value: "广州港",
                label: "广州港"
            }, {
                value: "厦门港",
                label: "厦门港"
            }, {
                value: "江阴港",
                label: "江阴港"
            }, {
                value: "镇江港",
                label: "镇江港"
            }, {
                value: "独山港",
                label: "独山港"
            }, {
                value: "苏州港",
                label: "苏州港"
            }],//港口数据
            shipNameValue: "",//船名
            voyageNumberValue: "",//航次
            portArea: { //港区
                portAreaValue: "",
                portAreaList: [{
                    value: '选项1',
                    label: '黄金糕'
                }],
            },
            transitPortValue: "", //中转港
            portOfDestinationValue: "", //目的港
            businessNoValue: "", //业务编号
            etdValue: "", //ETD

            gatePointAddressValue: "", //门点地址
            doorpointRadio: "0", //门点地址单选
            // 工厂联系人····
            factoryContactValue: "",  //工厂联系人
            contactPhoneValue: "", //联系人电话
            peerApproachValue: "", //通行办法
            // 是否位于保税区内```
            bondedAreaRadio: '0', //保税区单选
            keepOnRecordRadio: '0', //备案单选

            // 件毛体数据提供方式
            pieceTrichomeRadio: 0,//件毛体单选
            unfixedRadio: "",

            pullcartExtList: [
                // {
                //     "box_making_time": "2022-12-04 09:30", //做箱时间
                //     "bill_no": "2",    //提单号
                //     "box_type": "3",//箱型
                //     "pkg": "4",  //件数
                //     "kgs": "5",  //重量KG
                //     "cbm": "6",  //体积
                //     "dayton": "7",  //吨
                //     "product_name": "8",//品名
                //     "is_white_card": "是",  //是否为白卡
                //     "withholding": "",  //预提
                //     "house_awb": "12",  //副提单
                // }
                // , {
                //     "box_making_time": "2022-12-1", //做箱时间
                //     "bill_no": "2",    //提单号
                //     "box_type": "3",//箱型
                //     "pkg": "4",  //件数
                //     "kgs": "5",  //重量KG
                //     "cbm": "6",  //体积
                //     "dayton": "7",  //吨
                //     "product_name": "8",//品名
                //     "is_white_card": "是",  //是否为白卡
                //     "withholding": "2022-11-28 32",  //预提
                //     "house_awb": "",  //副提单
                // }
            ],
            boxMakingRequirements: "", //做箱要求（选填）
            placedRadio: "0", //是否已放设备单 单选
            noticeRadio: "0", //等通知放设备单 单选
            boxMakingTimeRadio: "0", //做箱时间是否等开港/通知  单选
            boxMakingTimeTrueRadio: "",

            selectDateValue: "", //选择日期
            selectTimeValue: "", //选择时间

            dataTruncationRadio: "0",//是否出数据截单 单选
            socBoxRadio: "0", //SOC箱 单选
            weighRadio: "0", //是否需要过磅

            delegationOrderRadio: "0", //委托舱单 单选
            entrustRadio: "0", //委托报关 单选
            testRadio: "0", //检验监装 单选
            testValue1: "", // 检验要求1
            testValue2: "", // 检验要求2

            flagOrderDetails: false, //订单详情
            flagSeeOrder: false, //查看
            seeIndex: '',
            seeDetailList: '',

            headerObj: {
                'authorization': localStorage.getItem("token")
            },
            powerOfAttorney: ""
        }
    },

    methods: {
        showypcdBtn(){  //预配舱单传输合作协议弹窗
            this.showDialogypcd = true
        },
        showbgfwBtn(){  //报关服务协议弹窗
            this.showDialogbgfw = true
        },
        showtyfwBtn(){  //通用服务协议弹窗
            this.showDialogtyfw = true
        },
        handleOrderDetails() { //添加做箱计划
            this.flagOrderDetails = true
        },
        handleFlagcancel() {
            this.flagOrderDetails = false;
        },
        changeHandleDetermine() { //确定
            this.flagOrderDetails = false;
        },
        emitDetailList(detailList) { //添加
            this.pullcartExtList.push(detailList)
        },
        handleFlagSee() {
            this.flagSeeOrder = false;
        },
        changeHandleSee() { //确定
            this.flagSeeOrder = false;
        },
        handleSee(index, rows) { //查看
            this.flagSeeOrder = true;
            console.log(index, rows);
            this.seeDetailList = rows;
            this.seeIndex = index
        },
        emitModifyList(emitModify) {
            console.log(this.pullcartExtList[this.seeIndex]);
            // this.pullcartExtList[this.seeIndex] = emitModify;

            // this.$set(this.pullcartExtList, this.seeIndex, emitModify)
            this.pullcartExtList.splice(this.seeIndex, 1, emitModify)
            console.log("SDA", this.pullcartExtList[this.seeIndex]);
        },
        handleDelete(index, rows) { //删除
            rows.splice(index, 1);
        },
        handlePlaceOrder() { //提交订单
            var yaoqiu = ''
            if (this.testRadio == 1) {
                yaoqiu = this.testValue1
            } else if (this.testRadio == 2) {
                yaoqiu = this.testValue2
            }
            console.log("11", yaoqiu);
            if (!this.importPortValue) {
                this.$message({
                    showClose: true,
                    message: '请输入港口',
                    type: 'error'
                });
                return false
            } else if (!this.mainBillNoValue) {
                this.$message({
                    showClose: true,
                    message: '请输入主提单号',
                    type: 'error'
                });
                return false
            } else if (!this.shippingCompany.shippingCompanyValue) {
                this.$message({
                    showClose: true,
                    message: '请选择船公司',
                    type: 'error'
                });
                return false
            } else if (!this.shipNameValue) {
                this.$message({
                    showClose: true,
                    message: '请输入船名',
                    type: 'error'
                });
                return false
            } else if (!this.voyageNumberValue) {
                this.$message({
                    showClose: true,
                    message: '请输入航次',
                    type: 'error'
                });
                return false
            } else if (!this.portArea.portAreaValue) {
                this.$message({
                    showClose: true,
                    message: '请选择港区',
                    type: 'error'
                });
                return false
            } else if (!this.portOfDestinationValue) {
                this.$message({
                    showClose: true,
                    message: '请输入目的港',
                    type: 'error'
                });
                return false
            } else if (!this.gatePointAddressValue) {
                this.$message({
                    showClose: true,
                    message: '请输入门点地址',
                    type: 'error'
                });
                return false
            } else if (!this.factoryContactValue) {
                this.$message({
                    showClose: true,
                    message: '请输入工厂联系人',
                    type: 'error'
                });
                return false
            } else if (!this.contactPhoneValue) {
                this.$message({
                    showClose: true,
                    message: '请输入联系人电话',
                    type: 'error'
                });
                return false
            } else if (this.doorpointRadio == 1 && this.peerApproachValue == '') {
                this.$message({
                    showClose: true,
                    message: '请输入通行办法',
                    type: 'error'
                });
                return false

            } else if (!this.selectDateValue || !this.selectTimeValue) {
                this.$message({
                    showClose: true,
                    message: '请选择截单时间',
                    type: 'error'
                });
                return false
            } else {

                console.log("$@#$FDA");
                var token = sessionStorage.getItem("token")
                var pullcartExtList = this.pullcartExtList;
                axios.post(`${baseUrl}/trailer/addOrder`, {
                    "haven": this.importPortValue,  //港口
                    "dock": this.portArea.portAreaValue,  //港区
                    "bill_no": this.mainBillNoValue,    //提单号
                    "shipping": this.shippingCompany.shippingCompanyValue, //船公司
                    "ship_name": this.shipNameValue,    //船名
                    "voyage": this.voyageNumberValue,//航次
                    "entreport": this.transitPortValue || '',  //中转港
                    "destination": this.portOfDestinationValue, //目的港
                    "business_no": this.businessNoValue,  //业务编号
                    "etd": this.etdValue,//etd
                    "store_address": this.gatePointAddressValue,//门店地址
                    "factory_contact": this.factoryContactValue,//工厂联系人
                    "factory_mobile": this.contactPhoneValue,//工厂联系电话
                    "is_bonded": Number(this.bondedAreaRadio),//是否保税区   0否，1是
                    "is_beian": Number(this.keepOnRecordRadio),// 是否备案  0否，1是
                    "factory_exclusion": Number(this.doorpointRadio),//工厂是否禁区, 0否，1是
                    "passage_method": this.peerApproachValue,//通行办法
                    "pkg_source": this.pieceTrichomeRadio,//件毛体数据提供方式
                    "note": this.boxMakingRequirements,//做箱备注

                    "is_elr": Number(this.placedRadio),   //是否已放设备单  0否，1是
                    "etc_elr": Number(this.noticeRadio),   //等通知放设备单  0否，1是
                    "box_making": Number(this.boxMakingTimeRadio), // 做箱约定是否等开港通知  0否，1是
                    "cut_off_time_beg": this.selectDateValue + ' ' + this.selectTimeValue,  //截单时间起
                    "cut_off_time_end": this.selectDateValue + ' ' + this.selectTimeValue,  //截单时间止
                    "is_cut_off": Number(this.dataTruncationRadio),  //是否出数据截单   0否，1是
                    "shipping_space": Number(this.socBoxRadio),  //soc箱   0否，1是
                    "is_need_weigh": Number(this.weighRadio),  //是否需要过磅   0否，1是
                    "wtcddf": Number(this.delegationOrderRadio),  //委托舱单代发   0否，1是
                    "appendix": this.powerOfAttorney || '',
                    "need_customs": Number(this.entrustRadio),  //委托报关   0否，1是
                    "need_examine": this.testRadio,  //检验监装pullcartExtList
                    "examine_detail": yaoqiu || '',  //检验要求
                    pullcartExtList
                }, {
                    headers: {
                        'authorization': token
                    }
                })
                    .then(res => {
                        if (res.data.success == true) {
                            let message = res.data.message;
                            this.$message({
                                showClose: true,
                                message: message,
                                type: 'success'
                            });
                            this.$router.push("/PersonalCenter/TrailerOrder")
                        } else {
                            let message = res.data.message;
                            this.$message({
                                showClose: true,
                                message: message,
                                type: 'error'
                            });
                            return false
                        }
                        // console.log(res)
                    })
                    .catch(err => {
                        console.error(err);
                    })
            }
        },
        handlePrice() {
            this.priceMaskFlag = true;
        },
        handleClose() { this.priceMaskFlag = false; },
        handNOypcd() { this.showDialogypcd = false; },
        handNObgfw() { this.showDialogbgfw = false; },
        handNOtyfw() { this.showDialogtyfw = false; },
        handleAddFil() {

        },

        handleExceed(files, fileList) {
            console.log(fileList);
            this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件`);
            /* ，共选择了 ${files.length + fileList.length} 个文件 */
        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePreview(file) {
            console.log(file.name);
        },
        handleAvatarSuccess1(res, file) {
            this.imageUrl = URL.createObjectURL(file.raw);
            console.log(file.response.result.fileName);
            this.powerOfAttorney = file.response.result.fileName; //企业委托书
        },
    },
}
</script>

<style lang="scss" scoped>

::v-deep .el-dialog__header{
    text-align: center;
}
.flexibleLayouts {
    display: flex;
}

.asterisk {
    font-size: 18px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #D4252C
}

// ::v-deep .el-input__inner {
//     padding-left: 15px;
// }
.priceMask {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.30);
    z-index: 10;

    .priceBox {
        width: 1500px;
        height: 100%;
        margin: 60px auto 0;
        // transform: translateX(-10px);
        position: relative;

        img {
            width: 100%;
            // height: 100%;
        }

        .maskClose {
            position: absolute;
            right: 8px;
            top: 8px;
            font-size: 25px;
            cursor: pointer;
        }
    }
}

.TrailerBox {
    width: 100%;
    height: 100%;
    margin: -100px auto 0;
    background-color: rgba(51, 51, 51, 0.10);
    padding-bottom: 40px;

    .TrailerTitle {
        margin: 0 auto 24px;
        width: 1500px;
        font-size: 16px;
        font-weight: 800;
        color: #FFFFFF;
    }

    .TrailerContentBox {
        width: 1500px;
        // height: 706px;
        background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;
        margin: 0 auto;
        padding-bottom: 40px;

        // 头部输入框
        .contentHeaderInputs {
            padding-top: 24px;
            padding-bottom: 40px;
            border-bottom: 1px solid rgba(153, 153, 153, .5);

            //上排
            .iptList {
                display: flex;
                justify-content: space-between;
                padding: 0 24px 0 24px;
                line-height: 56px;

                .headerInputItem {
                    display: flex;

                    span:nth-child(1) {
                        font-size: 16px;
                        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                        font-weight: 400;
                        color: #D4252C;
                    }

                    span:nth-child(2) {
                        font-size: 14px;
                        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                        font-weight: 400;
                        color: #2B2B2B;
                    }

                    .ipt {
                        margin-left: 20px;
                    }
                }
            }
        }

        //联系地址···
        .contactAddress {
            margin-top: 40px;
            padding-left: 24px;

            .gatePointAddress {
                // align-items: center;
                height: 40px;
                line-height: 40px;

                span:nth-child(1) {
                    font-size: 18px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #D4252C
                }

                span:nth-child(2) {
                    font-size: 14px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #333333;
                    margin-left: 4px;
                }

                .ipts {
                    display: flex;
                    width: 510px;
                    height: 40px;
                    background: #FFFFFF;
                    border-radius: 4px 4px 4px 4px;
                    margin-left: 16px;
                    overflow: hidden;

                    .el-input {
                        flex: 1;
                        border: 4px 0 0 4px;
                    }

                    .img {

                        width: 40px;
                        height: 40px;
                        background: linear-gradient(180deg, #68B7FF 0%, #1890FF 100%);
                        border-radius: 2px 2px 2px 2px;
                        cursor: pointer;
                        overflow: hidden;

                        img {
                            display: block;
                            margin: auto;
                            transform: translateY(25%);
                        }
                    }
                }
            }
        }

        //工厂联系人
        .factoryContact {
            height: 40px;
            line-height: 40px;
            margin-top: 32px;

            .factoryContactItem {
                margin-left: 24px;

                span:nth-child(1) {
                    font-size: 18px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #D4252C
                }

                span:nth-child(2) {
                    font-size: 14px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #333333;
                    margin-left: 4px;
                }

                .radios {
                    margin-left: 16px;

                    .el-radio {
                        margin-left: 16px;
                    }

                    .el-radio:nth-child(1) {
                        margin-left: 0;
                        margin-right: 0;
                    }
                }

                .ipt {
                    width: 300px;
                    height: 40px;
                    margin-left: 16px;

                    ::v-deep .el-input {
                        width: 100%;
                        height: 100%;

                        .el-input__inner {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }

            .factoryContactItem:nth-child(1) {
                margin-left: 0;
            }
        }

        // 是否位于保税区内···
        .bondedArea {
            margin-top: 42px;

            .bondedAreaConent {

                span:nth-child(2) {
                    font-size: 14px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #333333;
                    margin-left: 4px;
                }

                .el-radio {
                    margin-left: 16px;
                    margin-right: 0;
                }
            }
        }



        .keepOnRecord {
            margin-left: 40px;

            span:nth-child(2) {
                font-size: 14px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #333333;
                margin-left: 4px;
            }

            .el-radio {
                margin-left: 16px;
                margin-right: 0;
            }
        }

        // 件毛体数据提供方式````   
        .pieceTrichome {
            margin-top: 32px;

            span:nth-child(2) {
                margin-left: 4px;
                font-size: 14px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #333333;
            }

            .el-radio {
                margin-left: 16px;
                margin-right: 0;
            }
        }

        // 表格```
        .tabs {
            width: 100%;
            padding: 0 24px;
            margin-top: 40px;
            border-top: 1px solid rgba(153, 153, 153, .5);
            padding-top: 20px;

            .addPackingPlan {
                width: 160px;
                height: 32px;
                background: #FFC13B;
                border-radius: 4px 4px 4px 4px;
                text-align: center;
                line-height: 32px;
                font-size: 16px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #FFFFFF;
                margin: 16px auto 0;
                cursor: pointer;
            }

            .inputBox {
                width: 100%;
                // height: 62px;
                background: #FFFFFF;
                border-radius: 4px 4px 4px 4px;
                // border: 1px solid #999999;
                margin: 16px auto 0;

                .el-input {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        // 是否已放设备单```
        .equipmentList {
            // padding: 0 24px;

            .whether {
                span:nth-child(2) {
                    font-size: 14px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #333333;
                    margin-left: 4px;
                }

                .el-radio {
                    margin-left: 16px;
                    margin-right: 0;
                }
            }

            .notice {
                margin-left: 40px;

                span:nth-child(2) {
                    font-size: 14px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #333333;
                    margin-left: 4px;
                }

                .el-radio {
                    margin-left: 16px;
                    margin-right: 0;
                }
            }

        }

        .cutOffTime {
            height: 40px;
            margin-top: 19px;

            .cutOffTimeInputBox {
                line-height: 40px;

                span:nth-child(2) {
                    font-size: 14px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #333333;
                    margin-left: 4px;
                }

                .selectDate {
                    margin-left: 16px;
                }

                .selectTime {
                    margin-left: 8px;
                    width: 220px;
                    height: 40px;
                    background: #FFFFFF;
                    border-radius: 4px 4px 4px 4px;
                }
            }

            .dataTruncation {
                margin-left: 26px;
                line-height: 40px;

                .el-radio {
                    margin-left: 16px;
                    margin-right: 0;
                }
            }
        }

        .socBox {
            margin-top: 20px;

            span:nth-child(2) {
                font-size: 14px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #333333;
                margin-left: 4px;
            }

            .el-radio {
                margin-left: 16px;
                margin-right: 0;
            }

        }

        .weigh {
            margin-top: 35px;

            span:nth-child(2) {
                font-size: 14px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #333333;
                margin-left: 4px;
            }

            .el-radio {
                margin-left: 16px;
                margin-right: 0;
            }
        }

        // 添加装箱附件```
        .packingAccessories {
            margin-top: 56px;
            padding: 0 24px;

            .upload-demo {
                display: flex;
                align-items: center;

                .authenTitle {
                    // line-height: 35px;
                    margin-top: 6px;
                    padding-right: 10px;
                }
            }

            .title {
                font-size: 14px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #FFC13B;
                cursor: pointer;
                line-height: 25px;
            }

            .delegationOrder {
                margin-top: 32px;

                .delegationOrderTop {

                    span:nth-child(2) {
                        font-size: 14px;
                        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                        font-weight: 400;
                        color: #333333;
                        margin-left: 4px;
                    }

                    .agreement {
                        font-size: 14px;
                        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                        font-weight: 400;
                        color: #1890FF;
                        margin-left: 60px;
                        cursor: pointer;
                    }

                    .el-radio {
                        margin-left: 16px;
                        margin-right: 0;
                    }
                }

                .delegationOrderBott {
                    margin-top: 8px;
                    font-size: 12px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #D4252C;

                    span:nth-child(2) {
                        font-size: 14px;
                        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                        font-weight: 400;
                        color: #333333;
                    }

                    p {
                        span:nth-child(2) {
                            cursor: pointer;
                            color: #1890FF;
                        }
                    }

                    .el-radio {
                        margin-left: 16px;
                        margin-right: 0;
                    }
                }


            }

            .testRadios {

                // margin-left: 130px;
                .delegationOrderTop {


                    .el-radio {
                        margin-left: 60px;
                        margin-right: 0;
                    }
                }

                .delegationOrderBott {
                    height: 32px;
                    line-height: 32px;
                    margin-top: 15px;

                    span {
                        opacity: 0;
                    }

                    span:nth-child(2) {
                        font-size: 14px;
                        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                        font-weight: 400;
                        color: #333333;
                        margin-left: 4px;
                    }

                    .el-radio {
                        margin-left: 60px;
                        margin-right: 0;
                        line-height: 32px;
                    }

                    .requirement {
                        line-height: 32px;
                        margin-left: 40px;
                        font-size: 14px;
                        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                        font-weight: 400;
                        color: #333333;

                        .ipt {
                            width: 500px;
                            height: 32px;
                            background: #FFFFFF;
                            border-radius: 4px 4px 4px 4px;
                            margin-left: 16px;
                        }
                    }
                }
            }
        }
    }

    // 提交订单
    .placeOrder {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1500px;
        height: 64px;
        background: #FFFFFF;
        margin: 16px auto 0;
        padding: 1px;

        .preservation {
            width: 150px;
            height: 40px;
            border-radius: 30px 30px 30px 30px;
            text-align: center;
            line-height: 40px;
            font-size: 18px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #1890FF;
            border: 1px solid #1890FF;
            margin-right: 59px;
            cursor: pointer;
        }

        .submits {
            width: 228px;
            height: 40px;
            background: #1890FF;
            border-radius: 30px 30px 30px 30px;
            text-align: center;
            line-height: 40px;
            font-size: 18px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #FFFFFF;
            cursor: pointer;
        }
    }
}
</style>