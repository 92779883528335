<template>
    <div class="agreement-box">
        <div class="paragraph-box">
            <b>尊敬的用户：</b>
            <p>本程序是由宁波顺欧供应链管理有限公司（下列简称“本公司”）开发，所有权和运营权归本公司所有。本协议是您与本公司之间的法律协议，请仔细阅读本协议。在未同意本协议之前，您无法擅自使用本程序。如果您点击“同意”按钮即表示您完全接受本协议项下的全部条款。本协议可由本公司随时更新，您应当及时关注并同意。本公司的通知、公告、声明或其它类似内容是本协议的一部分。</p>
        </div>
        <div class="paragraph-box">
            <h3>第一部分 服务内容</h3>
            <p>一．本公司为您提供代理报关服务。</p>
            <p>二．您须在具有法律效力的协议中得到委托报关授权。</p>
            <p>三．本协议项下服务为收费服务，您须向本公司支付服务费用。</p>
            <p>四．您不得以任何方式向其他人（包括您的关联公司和子公司等）出售、转让或向协议约定使用范围人以外的其他任何人传播散布基于本协议而获得的信息。</p>
        </div>
        <div class="paragraph-box">
            <h3>第二部分 权利义务</h3>
            <h4>五．您需确保知晓并执行下列义务：</h4>
            <p>5.1您承诺在不违反相关法律法规及本协议约定的情况下使用本公司提供的委托报关授权服务；</p>
            <p>5.2您承诺提供的报关资料，认真做好报关资料的交接登记、签收等记录手续。同时您确保报关清单和单证齐全、有效、无误、清楚，您对报关单证和清单负责并保证单货相符；</p>
            <p>5.3您应根据代理报关的需要给予相应配合与解释；</p>
            <p>5.4您承诺报关时的货物品名应与集装箱内相符，如果因此造成的任何包括但不限于民事、行政、经济和刑事处罚等责任，均由您承担；</p>
            <p>5.5您承诺及时提供报关资料，如因您的资料提供延误造成改船、延误而产生的所有费用和责任由您自行承担；</p>
            <p> 5.6您提供的报关资料如有错误，本公司审核并反馈单证存在的问题后，您应根据海关的要求及时修改。如果因为您的延误，造成未及时发现存在的问题导致改船、延误而产生的所有费用和责任均由您承担；</p>
            <p>5.7如果报关手续已完成，因船公司或您的原因需要改船产生查验费用，我司会尽力帮助您解决，但费用由您自行承担；</p>
            <h4>六．本公司的权利义务：</h4>
            <p>6.1本公司确保对您委托的代理报关业务具备专业能力；</p>
            <p>6.2本公司承诺自收到所有的报关资料（货物清单、三联单、核销单等）后在截关期限内完成报关业务，如因本公司原因（海关及单证因素除外）未及时放行通关，造成改船、延误而产生的所有费用和责任均由本公司承担；</p>
            <p>6.3本公司收到您的报关资料后将及时审核并反馈单证存在的问题，确认报关资料无误后及时向海关申报，正常情况下保证货物能及时通关放行。如果因为本公司的延误未及时发现存在的问题，造成改船、延误而产生的费用和责任均由本公司承担；</p>
            <p>6.4因不可抗力原因造成的误船、改船以及海关系统故障或电子信息不及时造成无法在时限内查验放行所产生的费用需另行协商解决；</p>
            <p>6.5如因本公司理单不及时或自身原因将单证遗失而造成您无法退税及核销，本公司会及时帮您补齐单证，费用由本公司方承担。但因快递公司遗失单证，补证过程中您需配合本公司准备所需的资料，所产生的费用由本公司与快递公司协商解决；</p>
            <h4>七．我们双方对合作事项及本协议的约定均负有保密义务。对双方在交流、沟通、谈判以及本协议签署、履行等过程中知悉的对方的商业机密和机密信息，双方均承担保密义务，并有责任约束本方知情人员承担保密义务。未经对方书面同意，任何一方不得向任何第三方披露、复制和/或授权使用。该等保密义务不因本协议的解除、中止、终止、变更而失效。</h4>
        </div>
        <div class="paragraph-box">
            <h3>第三部分 违约责任</h3>
            <h4>八．本协议期限内，您未能按照协议约定支付服务费用的，本公司有权终止提供服务，由此引发的后果由您自行承担。本公司未能按照协议约定提供代理报关服务的，您有权拒付服务费或单方要求终止本协议。</h4>
            <h4>九．本协议的有效期、付费约定、开票和付费对象、送达条款、法律管辖等条款均取决于您与本公司的运输协议(或者运输代理协议）。代理报关的费用将与本公司及关联公司宁波顺欧供应链管理有限公司的运费合并开票给您，您需按照运输协议(或者运输代理协议）及时支付费用。</h4>
        </div>
    </div>
</template>

<script>
export default {
    name: "Customs_Declaration",
    data() {
        return {}
    }
}
</script>

<style lang="scss" scoped>
.agreement-box{
    height: 700px;
    overflow-y: auto;
}
.paragraph-box{
    margin-bottom: 20px;
    h3{
        margin-bottom: 10px;
    }
    h4{
        margin-top: 5px;
        margin-bottom: 5px;
    }
    p{
        font-size: 14px;
        color: #333;
        text-indent: 1em;
    }
}
</style>