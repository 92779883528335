<template>
    <div class="agreement-box">
        <div class="paragraph-box">
            <h3>1.总则</h3>
            <p>a)除非另有书面协议，或(i)和代表政府、政府团体或任何其它公众实体履行服务的管理法规不一致，或(ii)和当地法律的强制规定不一致，任何鸭嘴兽的关联公司或任何它们的代理(单称“公司”)和客户(“契约关系”)所有产生的合同关系，都受本通用服务条款(以下称为“通用条款”)约束。</p>
            <p>b)本公司可为发出指令的人或实体(私人、公众或政府)(以下称为“客户”)提供服务。</p>
            <p>c)除非本公司事先收到客户相反的书面指令，任何其它方都无权做出指令，特别是关于服务范围或提交做出的报告或证书(“结果报告”)方面的指令。无论是经客户指示或是依照环境、贸易惯例、作法或实践做出判断，客户在此不可撤销地授权本公司提交“结果报告”给第三方。</p>
        </div>

        <div class="paragraph-box">
            <h3>2.提供服务</h3>
            <p>a)本公司根据经本公司确认的委托人的具体指令，以合理的审慎和技能提供服务。若无此指令，则根据：</p>
            <p>(1)本公司的任何标准委托单或标准规格单中的条款；和/或</p>
            <p>(2)任何有关的贸易惯例、作法或实践；和/或</p>
            <p>(3)本公司认为在技术、操作和/或财务方面适当的方法。</p>
            <p>b)结果报告中陈述的信息来源于检验/测试的结果，执行检验/测试程序是根据客户的指令，和/或根据任何技术标准，贸易惯例或实践的结果评估，或应该被考虑的在我们专业建议中的其它情况。</p>
            <p>c)对样品检测后出具的结果报告仅仅反映本公司对该样品的评价，不反映对被抽取样品的一批货物的评价。</p>
            <p>d)如客户要求本公司见证任何第三方的工作，客户同意，本公司的唯一责任是在第三方工作时出现在现场并传递该结果或证实其工作中发生的事情。客户同意，本公司对第三方使用的设备、仪器和测量器具的状况和校准、所用的分析方法、第三方人员的资格、行为或疏漏，以及分析结果均不负责。</p>
            <p>e)本公司出具的结果报告只反映在工作当时所记录的事实，而且限于所收到指令的范围内，若无指令时，则限于所用的本条款2(a)中给出的可选择参照的范围。本公司没有责任涉及或报告所收到的专门指令或所用的可选择参照范围以外的事实或情况。</p>
            <p>f)本公司可委派代理或分包商承担全部或部分服务，客户授权本公司向代理或分包商提供其所承担服务的全部必要的信息。</p>
            <p>g)本公司如收到涉及客户和第三方签订的契约文件或第三方的文件，如销售合同、信用证、提单等，这些文件仅供参考用，而不扩展或限制经本公司接受的服务范围或职责。</p>
            <p>h)客户确认，本公司在提供服务时既不取代客户或任何第三方的位置，也不免除它们应负的任何职责，此外也不承担、不削减、不免除、不承诺解除客户对任何第三方或任何第三方对客户的任何责任。</p>
            <p>i)所有样品的保留期最长为3个月或样品性质允许的更短期限，到期后样品退给客户或由本公司自行处理，此后本公司终止对该样品的任何责任。样品存储期超出3个月所产生的存储费由客户支付。如样品退给客户，由客户支付退运费用。如产生样品的特殊处理费用，由客户支付。</p>
        </div>

        <div class="paragraph-box">
            <h3>3.客户的责任</h3>
            <p>客户要：</p>
            <p>a)保证及时提供足够的信息、指令和文件(任何情况下不得晚于所要求的工作前48小时)，以便所要求的服务得以实施；</p>
            <p>b)为本公司的代表取得到达工作地点的所有必要的通行权，并采取一切必要的措施，消除或纠正服务实施中遇到的任何障碍或干扰；</p>
            <p>c)如有要求，根据服务实施的需要提供任何特殊设备和人员；</p>
            <p>d)无论本公司通知要求与否，要采取一切必要的措施，确保实施服务时的工作环境、场所和装置的平安和安全；</p>
            <p>e)对任何委托、样品或实验中包含的任何已知的实际或潜在危险或危害，如放射性、有毒、有害或爆炸元素或物质、环境污染或中毒的存在和危险，要事先通知本公司；</p>
            <p>f)按照和第三方的任何有关销售合同或其它合同及法律，全面行使全部权利和清偿全部债务。</p>
        </div>

        <div class="paragraph-box">
            <h3>4.收费和支付</h3>
            <p>a)在本公司接受客户委托或合同磋商时未确定收费额的，应依照本公司的标准费率(有可能调整)，并且全部应交税款由客户支付。</p>
            <p>b)除发票上确定了更短期限外，客户应不晚于相关发票日期起30天，或本公司在发票上确定的期限(到期日)支付全部应付给本公司的费用。如未按时付款，则要按1.5%的月息(或在发票上确定的其它利率)支付自到期日起至(包括)实际收到付款日的利息。</p>
            <p>c)客户无权因声称对本公司的任何争端、反诉或抵销，而留置或延迟支付应付给本公司的任何款项。</p>
            <p>d)本公司可决定向任何有管辖权的法院就收取未付款提出诉讼。</p>
            <p>e)客户应支付本公司全部的收账费用，包括律师费和有关开支。</p>
            <p>f)一旦在实施服务过程中出现任何不可预见的问题和费用，本公司要尽力通知客户并有权收取附加费，以弥补完成该服务必需的额外时间和开支。</p>
            <p>g)如果因任何超出本公司控制的原因，包 括客户失于履行它在上述第3条中的任何责任，本公司不能履行全部和部分服务时，本公司依然有权收取：</p>
            <p>(1)本公司发生的所有不可偿还费用的总合；</p>
            <p>(2)按比例支付的等于实际上已实施的服务部分的约定费用。</p>
        </div>

        <div class="paragraph-box">
            <h3>5.服务的暂停和终止</h3>
            <p>如出现以下情况，本公司有权立即且不承担任何责任地暂停或终止提供服务：</p>
            <p>a)客户失于履行任何他应尽的职责，而且在通知其过失后10天内客户不作补救；或</p>
            <p>b)客户的任何暂停付款、与债权人做出安排、破产、无力偿付、破产管理或停业。</p>
        </div>

        <div class="paragraph-box">
            <h3>6.责任和赔偿</h3>
            <p>(a)责任范围</p>
            <p>(1)本公司既不是保险商也不是担保人，不承担这方面的任何责任。客户寻求保证不损失或不损害，应该适当投保。</p>
            <p>(2)结果报告的出具是以客户或其代表客户提供的信息、文件和/或样品为基础，并且仅仅是为了客户的利益，而且该客户应当对其在结果报告基础上所采取的其认为合适的行为负责。对任何根据该结果报告已采取或没采取的行动，对因提供给本公司不清楚、不正确、不完全、误导或虚假信息导致的任何不正确结果，无论本公司还是公司的任何官员、雇员、代理或分包商都不应为此对客户或任何第三方承担责任。</p>
            <p>(3)对因任何超出本公司控制的原因，包括客户失于履行他的任何责任而直接或间接导致的任何延期、部分或全部服务不能实施，本公司不承担责任。</p>
            <p>(4)本公司对任何性质和不管如何产生的损失、损害或费用的任何赔偿责任，在任何情况下都不超过付给发生索赔的该项具体服务的费用总额的十倍或二万元人民币(或等值本国货币)这两个金额中较少的一个。</p>
            <p>(5)本公司不负责任何间接或其他衍生性之损失，包括且不限于利润损失、业务损失、机会损失、商誉损失及产品召回之成本。本公司亦不负责可能由客户所造成之第三者的任何损失、损害或费用之索赔(包括且不限于产品责任之索赔)。</p>
            <p>(6)如有任何索赔，客户必须在发现所谓证明索赔的事实起30天内书面通知本公司，并且除非在自下述之日起的一年内提起诉讼，本公司在任何情况下都被免除对损失、损害或费用的所有索赔的全部责任：</p>
            <p>(6)如有任何索赔，客户必须在发现所谓证明索赔的事实起30天内书面通知本公司，并且除非在自下述之日起的一年内提起诉讼，本公司在任何情况下都被免除对损失、损害或费用的所有索赔的全部责任：</p>
            <p>i.发生索赔的服务被本公司实施的日期；或</p>
            <p>ii.任何声称未实施的服务应完成的日期。</p>
            <p>(b)赔偿：客户应保证、不伤害并赔偿本公司及其官员、雇员、代理和分包商，抵偿任何第三方提出的和与实施的、声称实施的、或未实施的任何服务有关的，无论是任何性质和无论如何发生的损失、损害或费用，包括全部法律开支和有关费用的全部索赔(实际的或要发生的)。</p>
        </div>

        <div class="paragraph-box">
            <h3>7.其它</h3>
            <p>(c)未经本公司事先书面授权，不允许以广告宣传为目的使用本公司的名称和注册商标。</p>
            <p>(b)在提供服务的过程中和其后的一年内，客户不得直接或间接诱惑、怂恿或提出聘用本公司雇员，使其离开本公司的职位。</p>
            <p>(c)未经本公司事先书面授权，不允许以广告宣传为目的使用本公司的名称和注册商标。</p>
        </div>

        <div class="paragraph-box">
            <h3>8.适用法律、管辖权与争议解决</h3>
            <p>除非另行约定，所有产生的或与合约双方有关的争端都要受中国实体法的管辖，但不包括任何冲突法，而所有的争端应按上海国际仲裁中心的仲裁法则，由依据该法则规定指定的一个和多个仲裁员最终裁决。仲裁应在宁波，使用中文进行。</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "General_Service",
    data() {
        return {}
    }
}
</script>

<style lang="scss" scoped>
.agreement-box{
    height: 700px;
    overflow-y: auto;
}
.paragraph-box{
    margin-bottom: 20px;
    h3{
        margin-bottom: 10px;
    }
    h4{
        margin-top: 5px;
        margin-bottom: 5px;
    }
    p{
        font-size: 14px;
        color: #333;
        text-indent: 1em;
    }
}
</style>