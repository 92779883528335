<template>
    <div class="orderDetails">
        <div class="orderDetailsBox">
            <div class="titles"><span>订单详情</span></div>
            <div class="boxMakingDate">
                <div class="boxMakingDatetitles">
                    <span class="asterisk">*</span>
                    <span>做箱日期</span>
                </div>
                <div class="tabs ">
                    <div class="riliTop flexibleLayouts" >
                        <div @click="pickPre(currentYear, currentMonth)"><img src="../../assets/homePage/left.png"
                                alt=""></div>
                        <div><span>{{ currentYear }} {{ currentMonth }}月</span></div>
                        <div @click="pickNext(currentYear, currentMonth)"><img src="../../assets/homePage/right.png"
                                alt=""></div>
                    </div>
                    <div class="flexibleLayouts">
                        <!-- <div class="leftArrow arrow" @click="handleMore" v-if="flagMounth">
                            <div class="hide_input" size="small">
                                <img src="../../assets/homePage/left.png" alt="">
                            </div>
                        </div> -->
                        <div class="dateLists flexibleLayouts">
                            <!-- <div class="dateItem" v-for="(item, index) in same_week" @click="select(item)" :key='index'>
                            <p style="background: rgba(153, 153, 153, .6);">{{ item.week }}</p>
                            <p>{{ item.name }} {{ item.week }}</p>
                            <p>额度： 无限制</p>
                        </div> -->
                            <div class="dateLists">
                                <ul class="weekdays  flexibleLayouts">
                                    <li class="dateItem">
                                        <p style="background: rgba(153, 153, 153, .6);">一</p>
                                    </li>
                                    <li class="dateItem">
                                        <p style="background: rgba(153, 153, 153, .6);">二</p>
                                    </li>
                                    <li class="dateItem">
                                        <p style="background: rgba(153, 153, 153, .6);">三</p>
                                    </li>
                                    <li class="dateItem">
                                        <p style="background: rgba(153, 153, 153, .6);">四</p>
                                    </li>
                                    <li class="dateItem">
                                        <p style="background: rgba(153, 153, 153, .6);">五</p>
                                    </li>
                                    <li class="dateItem">
                                        <p style="background: rgba(153, 153, 153, .6);">六</p>
                                    </li>
                                    <li class="dateItem">
                                        <p style="background: rgba(153, 153, 153, .6);">日</p>
                                    </li>
                                </ul>
                                <ul class="days">
                                    <!-- 循环-->
                                    <!-- <li v-for="dayobject in days"> -->
                                    <!-- v-if="index < 7" -->
                                    <template v-if="dayIndex">
                                        <li v-for="(dayobject, index) in days" :key="index" v-show="index < 7"
                                            @click="handleDays(dayobject.day, index)"
                                            :class="{ 'liActive': (new Date(dayobject.day).getTime() - new Date().getTime()) < 0 }">
                                            <!--本月-->
                                            <span v-if="dayobject.day.getMonth() + 1 != currentMonth"
                                                class="other-month">{{
                                                    dayobject.day.getDate()
                                                }}</span>

                                            <!--判断天数是否正确-->
                                            <span v-else>
                                                <!--今天-->
                                                <span
                                                    v-if="dayobject.day.getFullYear() == new Date().getFullYear() && dayobject.day.getMonth() == new Date().getMonth() && dayobject.day.getDate() == new Date().getDate()"
                                                    class="active">{{ dayobject.day.getDate() }}</span>
                                                <span v-else>{{ dayobject.day.getDate() }}</span>
                                            </span>

                                        </li>
                                    </template>
                                    <template v-else>
                                        <li v-for="(dayobject, index) in days" :key="index"
                                            @click="handleDays(dayobject.day, index)"
                                            :class="{ 'liActive': new Date(dayobject.day).getTime() < nowData, 'isXuanZhong': isXuanZhong == index }">
                                            <!--本月-->
                                            <span v-if="dayobject.day.getMonth() + 1 != currentMonth"
                                                class="other-month">{{
                                                    dayobject.day.getDate()
                                                }}</span>

                                            <!--判断天数是否正确-->
                                            <span v-else>
                                                <!--今天-->
                                                <span
                                                    v-if="dayobject.day.getFullYear() == new Date().getFullYear() && dayobject.day.getMonth() == new Date().getMonth() && dayobject.day.getDate() == new Date().getDate()"
                                                    class="active">{{ dayobject.day.getDate() }}</span>
                                                <span v-else>{{ dayobject.day.getDate() }}</span>
                                            </span>

                                        </li>
                                    </template>


                                </ul>
                            </div>
                        </div>
                        <!-- <div class="rightArrow arrow" v-if="flagMounth">
                            <div class="hide_input" size="small" @click="handleMore">
                                <img src="../../assets/homePage/right.png" alt="">
                                <span>更多</span>
                            </div>
                        </div> -->
                    </div>



                </div>
            </div>
            <div class="boxMakingDate2 flexibleLayouts">
                <div class="lefts">
                    <p>
                        <span class="asterisk">*</span>
                        <span>做箱时间</span>
                    </p>
                    <div class="ipt">
                        <!-- <el-date-picker v-model="boxMakingDateValue" type="date" placeholder="选择日期" prefix-icon="none"
                            value-format="yyyy-MM-dd"></el-date-picker> -->
                        <el-time-select v-model="boxMakingTimeValue" prefix-icon="none" :picker-options="{
                            start: '08:30',
                            step: '00:15',
                            end: '18:30'
                        }" placeholder="选择时间" value-format="HH:mm:ss AA">
                        </el-time-select>
                    </div>
                </div>
                <div class="rights">
                    <p>
                        <span class="asterisk">*</span>
                        <span>提单号</span>
                    </p>
                    <div class="ipt">
                        <el-input v-model="blNoValue" placeholder="提单号"></el-input>
                    </div>
                </div>
            </div>
            <!-- 预计货重(吨) -->
            <div class="cargoWeight flexibleLayouts">
                <div class="lefts">
                    <p>
                        <span class="asterisk">*</span>
                        <span>预计货重(吨)</span>
                    </p>
                    <div class="ipt">
                        <el-input v-model="estimatedCargoWeightValue" placeholder="预计货重(吨)"></el-input>
                    </div>
                </div>
                <div class="rights">
                    <p>
                        <span class="asterisk"></span>
                        <span>品名</span>
                    </p>
                    <div class="ipt">
                        <el-input v-model="productNameValue" placeholder="品名"></el-input>
                    </div>
                </div>
            </div>
            <!-- 箱型 -->
            <div class="boxTypes flexibleLayouts">
                <div class="box1">
                    <p>
                        <span class="asterisk">*</span>
                        <span>箱型</span>
                    </p>
                    <div class="ipt">
                        <!-- <el-input v-model="boxTypeValue" placeholder="请选择箱型"></el-input> -->
                        <el-select v-model="boxTypeValue" placeholder="请选择箱型">
                            <el-option v-for="item in boxTypeList" :key="item.value" :label="item.value"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="box1" style="margin-left: 10px;">
                    <p>
                        <span class="asterisk">*</span>
                        <span>箱量</span>
                    </p>
                    <div class="ipt">
                        <el-input v-model="caseQuantityValue" placeholder="箱量"></el-input>
                    </div>
                </div>
                <div class="box1" style="margin-left: 10px;">
                    <p>
                        <span class="asterisk">*</span>
                        <span>件数</span>
                    </p>
                    <div class="ipt">
                        <el-input v-model="numberValue" placeholder="件数"></el-input>
                    </div>
                </div>
                <div class="box1" style="margin-left: 10px;">
                    <p>
                        <span class="asterisk">*</span>
                        <span>毛重(kg)</span>
                    </p>
                    <div class="ipt">
                        <el-input v-model="grossWeightValue" placeholder="毛重(kg)"></el-input>
                    </div>
                </div>
                <div class="box1" style="margin-left: 10px;">
                    <p>
                        <span class="asterisk">*</span>
                        <span>体积(m³)</span>
                    </p>
                    <div class="ipt">
                        <el-input v-model="volumeValue" placeholder="体积(m³)"></el-input>
                    </div>
                </div>
            </div>
            <!-- 是否为白卡 -->
            <div class="whiteCard">
                <span class="asterisk">*</span>
                <span>是否为白卡</span>
                <el-radio v-model="whiteCardRadio" label="0">否</el-radio>
                <el-radio v-model="whiteCardRadio" label="1">是</el-radio>
            </div>
            <!-- 是否预提? -->
            <div class="withholding flexibleLayouts">
                <div class="withholdingLeft">
                    <div class="isTitle">是否预提?</div>
                    <div class="smallBox flexibleLayouts">
                        <div v-for="(item, index) in withholding" :key="index"
                            :class="{ 'handleactive': withholdingFlag == index }" @click="handlewithholding(index)">
                            <span>{{
                                item
                            }}</span>
                        </div>
                    </div>
                </div>
                <div class="withholdingRight">
                    <div v-if="withholdingFlag == 1">
                        <div class="isTitle">预提时间</div>
                        <div class="selestDatas flexibleLayouts">
                            <div class="selects">
                                <el-date-picker v-model="accrualTimeValue1" type="date" placeholder="选择日期"
                                    prefix-icon="none" value-format="yyyy-MM-dd">
                                </el-date-picker>
                            </div>
                            <div class="selects" style="margin-left: 32px;">
                                <el-time-select v-model="accrualTimeValue2" :picker-options="{
                                    start: '00:00',
                                    step: '00:30',
                                    end: '24:00'
                                }" placeholder="选择时间" prefix-icon="none" value-format="HH:mm:ss AA">
                                </el-time-select>
                            </div>
                        </div>
                    </div>
                    <div v-show="withholdingFlag == 2">
                        <div class="isTitle">预提堆场</div>
                        <div class="selestDatas">
                            <el-input v-model="accruedStorageYardValue" placeholder="请输入内容"></el-input>
                        </div>
                    </div>

                </div>
            </div>
            <!-- 预提堆场 -->
            <div class="withholdingyard flexibleLayouts">
                <div class="withholdingyardLeft">

                </div>
                <div class="withholdingyardRight">
                    <!-- <div class="yardTitle"><span>预提堆场</span></div>
                    <div class="yardInputBox">
                        <el-input v-model="accruedStorageYardValue" placeholder="请输入内容"></el-input>
                    </div> -->
                </div>
            </div>
            <!-- 按钮 -->
            <div class="buttons flexibleLayouts">
                <div class="cancelBtn" @click="handlecancel"><span>取消</span></div>
                <div class="determineBtn" @click="handleDetermine"><span>确定</span></div>
            </div>
        </div>
        <!-- <calendar_Component></calendar_Component> -->
    </div>
</template>

<script>
import calendar_Component from './calendar_Component.vue';
export default {
    name: "orderDetails",
    props: ['handleFlagcancel', 'changeHandleDetermine'],
    // components: { calendar_Component },
    data() {
        return {
            value1: "",
            dateDay: "",
            flagMounth: true,
            boxMakingDateValue: "",//做箱日期
            boxMakingTimeValue: "",//做箱时间
            blNoValue: "",//提单号
            estimatedCargoWeightValue: "", //预计货重(吨)
            productNameValue: "", //品名
            boxTypeValue: "", //箱型
            boxTypeList: [
                { value: '20GP' },
                { value: '40GP' },
                { value: '40HQ' },
                { value: '45HQ' },
                { value: '20OT' },
                { value: '40OT' },
                { value: '20NOR' },
                { value: '40NOR' },
                { value: '20FR' },
                { value: '40FR' },
                { value: '20ISO' }
            ],
            caseQuantityValue: "",//箱量
            numberValue: "",//件数
            grossWeightValue: "",//毛重
            volumeValue: "", //体积
            whiteCardRadio: "0", //是否为白卡 单选
            // 是否预提?
            withholding: [
                '暂不预提', '委托预提', '我方预提'
            ],
            withholdingFlag: 0,
            accrualTimeValue1: "", //预提时间 / 日期
            accrualTimeValue2: "", //预提时间 / 时间

            accruedStorageYardValue: "", //预提堆场
            detailList: {
                "box_making_time": "", //做箱时间
                "bill_no": "",    //提单号
                "box_type": "",//箱型
                "box_num": "",//箱量
                "pkg": "",  //件数
                "kgs": "",  //重量KG
                "cbm": "",  //体积
                "dayton": "",  //吨
                "product_name": "",//品名
                "is_white_card": "",  //是否为白卡
                "withholding": "",  //预提
                "withholding_beg": "",
                "withholding_end": "",
                "storage_yard": "",//预提堆场
                // "house_awb": "",  //副提单
            },
            // timer: null,
            // nowWeek: '',
            // nowDate: '',
            // nowTime: '',

            week: [],
            same_week: [],//保存当前最新的时间
            same_day: '',//当天的时间

            currentDay: 1,
            currentMonth: 1,
            currentYear: 1970,
            currentWeek: 1,
            days: [],
            dayIndex: false,
            liFlag: false,
            nowData: '',
            isXuanZhong: null,
        }
    },
    created() {
        this.nowData = new Date();
        console.log(this.nowData.setDate(this.nowData.getDate() - 1));
        this.datas();
        let that = this;
        that.initData(null);

    },
    mounted() {
        // this.setNowTimes()
    },

    methods: {

        initData: function (cur) {
            let that = this;
            let leftcount = 0;
            let date;
            if (cur) {
                date = new Date(cur);
                console.log(date.getFullYear());
            } else {
                let now = new Date();
                let d = new Date(that.formatDate(now.getFullYear(), now.getMonth(), 1));
                d.setDate(35);
                // date = new Date(that.formatDate(d.getFullYear(), d.getMonth() + 1, 1));
                date = new Date();
                console.log(date);

            }
            that.currentDay = date.getDate();
            that.currentYear = date.getFullYear();

            that.currentMonth = date.getMonth() + 1;
            that.currentWeek = date.getDay(); // 1...6,0
            if (that.currentWeek == 0) {
                that.currentWeek = 7;
            }
            let str = that.formatDate(that.currentYear, that.currentMonth, that.currentDay);
            that.days.length = 0;
            //初始化
            for (let i = that.currentWeek - 1; i >= 0; i--) {
                let d = new Date(str);
                d.setDate(d.getDate() - i);
                let dayobject = {};
                dayobject.day = d;
                that.days.push(dayobject);
            }
            for (let i = 1; i <= 35 - that.currentWeek; i++) {
                let d = new Date(str);
                d.setDate(d.getDate() + i);
                let dayobject = {};
                dayobject.day = d;
                that.days.push(dayobject);
            }

        },
        pickPre: function (year, month) {
            this.isXuanZhong = null;
            let that = this;
            let d = new Date(that.formatDate(year, month, 1));
            d.setDate(0);
            that.initData(that.formatDate(d.getFullYear(), d.getMonth() + 1, 1));
        },
        pickNext: function (year, month) {
            this.isXuanZhong = null;
            let that = this;
            let d = new Date(that.formatDate(year, month, 1));
            d.setDate(35);
            that.initData(that.formatDate(d.getFullYear(), d.getMonth() + 1, 1));
        },
        pickYear: function (year, month) {
            alert(year + "," + month);
        },
        // 返回 类似 2016-01-02 格式的字符串
        formatDate: function (year, month, day) {
            let y = year;
            let m = month;
            if (m < 10) m = "0" + m;
            let d = day;
            if (d < 10) d = "0" + d;
            return y + "-" + m + "-" + d
        },

        changeTimestamp() {
            let newDatas = this.days;
            var timestamp2 = new Date().getTime();//现在时间
            newDatas.forEach((v, i) => {
                var timestamp1 = new Date(v.day).getTime();//结束时间
                console.log('v', timestamp1);
                if (timestamp1 - timestamp2 < 0) {
                    // this.liFlag = false
                }
                else {
                    // this.liFlag = false
                    return
                }
            })
            // var timestamp1 = new Date(row.endTime).getTime();//结束时间
            // var timestamp2 = new Date().getTime();//现在时间
            // console.log(timestamp1 - timestamp2);
        },



        dateChenge(data) {
            this.boxMakingDateValue = data
        },
        setNowTimes() {
            //获取当前时间
            let myDate = new Date()
            let wk = myDate.getDay()
            let yy = String(myDate.getFullYear())
            let mm = myDate.getMonth() + 1
            let dd = String(myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate())
            let hou = String(myDate.getHours() < 10 ? '0' + myDate.getHours() : myDate.getHours())
            let min = String(myDate.getMinutes() < 10 ? '0' + myDate.getMinutes() : myDate.getMinutes())
            let sec = String(myDate.getSeconds() < 10 ? '0' + myDate.getSeconds() : myDate.getSeconds())
            let weeks = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
            let week = weeks[wk]
            this.nowDate = yy + '-' + mm + '-' + dd
            this.nowTime = hou + ':' + min + ':' + sec
            this.nowWeek = week
        },

        datas() {
            // 默认显示当天前一周的数据
            let data = []
            this.start = this.getDay(+7);
            this.end = this.getDay();
            for (let i = 6; i >= 0; i--) {
                data.push(this.getDay(+i))
            }
            var date = data.reverse()//得出一周的日期进行排序
            this.week = date;
            var date = this.week;
            var pkc = [];/* 用于存储展示的日期数据 */
            var weekday = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"];
            date.forEach((item, index) => {//循坏日期
                var f = new Date(item);
                var week = f.getDay()//计算出星期几
                var str1 = item.split('-');
                var strs = str1[1] + '-' + str1[2];

                var weeks = weekday[week]/* 将计算出来的时间带入数字得出中文 */
                var time = Math.round(new Date(item) / 1000)//时间戳转换
                var s = {}//用于存储每个日期对象
                s.date = item;
                s.name = strs;
                s.week = weeks;
                s.times = time;
                pkc.push(s)
            })
            this.same_week = pkc;
            //pkc存储着今天的年月日星期几，时间戳等
            this.same_day = pkc[0].date;//今天的数据
        },

        getDay(day) {
            var today = new Date();
            var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
            today.setTime(targetday_milliseconds); //注意，这行是关键代码
            var tYear = today.getFullYear();
            var tMonth = today.getMonth();
            var tDate = today.getDate();
            tMonth = this.doHandleMonth(tMonth + 1);
            tDate = this.doHandleMonth(tDate);
            return tYear + "-" + tMonth + "-" + tDate;
        },
        doHandleMonth(month) {
            var m = month;
            if (month.toString().length == 1) {
                m = month;
            }
            return m;

        },
        handleMore() { //更多
            this.flagMounth = !this.flagMounth;
            this.dayIndex = false
        },
        handleDays(data, index) {
            var y = data.getFullYear();
            var m = data.getMonth() + 1;
            m = m < 10 ? ('0' + m) : m;
            var d = data.getDate();
            d = d < 10 ? ('0' + d) : d;
            var h = data.getHours();
            var minute = data.getMinutes();
            minute = minute < 10 ? ('0' + minute) : minute;
            // return y + '-' + m + '-' + d+' '+h+':'+minute;

            let newData = new Date(data).getTime()
            if (newData < this.nowData) {
                return
            } else {
                this.isXuanZhong = index;
                let times = y + '-' + m + '-' + d
                this.boxMakingDateValue = times;
                console.log(this.boxMakingDateValue);
            }
            /* 
            new Date(dayobject.day).getTime() < nowData
            */

        },
        handlewithholding(index) { //是否预提?
            console.log(index);
            this.withholdingFlag = index;
            this.accrualTimeValue1 = ''
            this.accrualTimeValue2 = ''
            this.accruedStorageYardValue = '';
        },

        handlecancel() { //取消
            this.handleFlagcancel()
        },
        handleDetermine() { //确定
            console.log(this.boxMakingDateValue);
            console.log(this.boxMakingTimeValue);
            if (!this.boxMakingDateValue || !this.boxMakingTimeValue) { //做箱时间
                this.$message({
                    showClose: true,
                    message: '请选择做箱日期',
                    type: 'error'
                });
                return false
            } else if (!this.blNoValue) { //请输入提单号
                this.$message({
                    showClose: true,
                    message: '请输入提单号',
                    type: 'error'
                });
                return false
            } else if (!this.estimatedCargoWeightValue) {  //预计货重(吨)
                this.$message({
                    showClose: true,
                    message: '请输入预计货重(吨)',
                    type: 'error'
                });
                return false
            }
            // else if (!this.productNameValue) { //请输入品名
            //     this.$message({
            //         showClose: true,
            //         message: '请输入品名',
            //         type: 'error'
            //     });
            //     return false
            // } 
            else if (!this.boxTypeValue) {
                this.$message({
                    showClose: true,
                    message: '请选择箱型',
                    type: 'error'
                });
                return false
            } else if (!this.caseQuantityValue) {
                this.$message({
                    showClose: true,
                    message: '请输入箱量',
                    type: 'error'
                });
                return false
            } else if (!this.numberValue) {
                this.$message({
                    showClose: true,
                    message: '请输入件数',
                    type: 'error'
                });
                return false
            } else if (!this.grossWeightValue) {
                this.$message({
                    showClose: true,
                    message: '请输入毛重',
                    type: 'error'
                });
                return false
            } else if (!this.volumeValue) {
                this.$message({
                    showClose: true,
                    message: '请输入体积',
                    type: 'error'
                });
                return false
            } else if (!this.whiteCardRadio) {
                this.$message({
                    showClose: true,
                    message: '请选择是否为白卡',
                    type: 'error'
                });
                return false
            } else {
                this.changeHandleDetermine()
                let withholdingS = this.accrualTimeValue1 + ' ' + this.accrualTimeValue2;
                // this.detailList = this.detailList[{
                //     "box_making_time": this.boxMakingDateValue, //做箱时间
                //     "bill_no": this.blNoValue,    //提单号
                //     "box_type": this.boxTypeValue,//箱型
                //     "pkg": this.caseQuantityValue,  //件数
                //     "kgs": this.grossWeightValue,  //重量KG
                //     "cbm": this.volumeValue,  //体积
                //     "dayton": this.estimatedCargoWeightValue,  //吨
                //     "product_name": this.productNameValue,//品名
                //     "is_white_card": this.whiteCardRadio,  //是否为白卡
                //     "withholding": withholdingS,  //预提
                //     "house_awb": this.accruedStorageYardValue,  //副提单
                // }];
                this.detailList.box_making_time = this.boxMakingDateValue + ' ' + this.boxMakingTimeValue;
                this.detailList.bill_no = this.blNoValue;
                this.detailList.box_type = this.boxTypeValue;
                this.detailList.box_num = this.caseQuantityValue;
                this.detailList.pkg = this.numberValue;
                this.detailList.kgs = this.grossWeightValue;
                this.detailList.cbm = this.volumeValue;
                this.detailList.dayton = this.estimatedCargoWeightValue;
                this.detailList.product_name = this.productNameValue;
                this.detailList.is_white_card = this.whiteCardRadio;
                this.detailList.withholding = this.withholdingFlag;
                this.detailList.withholding_beg = withholdingS || '';
                this.detailList.withholding_end = withholdingS || '';
                this.detailList.storage_yard = this.accruedStorageYardValue;
                // this.detailList.house_awb = this.accruedStorageYardValue;

                console.log("##", this.detailList);
                this.$emit('emitDetailList', this.detailList)
            }
        }
    },
}
</script>

<style lang="scss" scoped>
::v-deep .el-picker-panel {
    display: block !important;
}

.flexibleLayouts {
    display: flex;
}

.liActive {
    background: rgba(153, 153, 153, 0.5) !important;
    cursor: not-allowed !important;
}

.isXuanZhong {
    background-color: #409eff !important;
    color: #FFFFFF !important;
}

.handleactive {
    border: 1px solid #1890FF !important;
    color: #1890FF !important;
}

.hide_input {
    position: relative !important;
}

.hide_input .el-date-editor {
    position: absolute; //绝对定位
    top: 0;
    left: 0;
    opacity: 0; //设置完全透明
}

::v-deep .el-input__inner {
    padding-left: 15px;
}

.asterisk {
    font-size: 18px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #D4252C
}

.orderDetails {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.30);
    // background-color: red;
    z-index: 10;

    .orderDetailsBox {
        position: absolute;
        top: 500px;
        left: 50%;
        transform: translate(-50%, 0%);
        width: 800px;
        // height: 710px;
        background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;
        padding: 0 18px 8px;

        .titles {
            height: 56px;
            line-height: 56px;
            font-size: 18px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #333333;
            border-bottom: 1px solid rgba(153, 153, 153, .5);
        }

        .boxMakingDate {
            margin-top: 16px;

            .boxMakingDatetitles {
                span:nth-child(2) {
                    margin-left: 4px;
                    font-size: 14px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #333333;
                }
            }

            .tabs {
                // width: 764px;
                min-height: 64px;
                background: #FFFFFF;
                margin-top: 8px;
                // border: 1px solid #999999;

                .riliTop {
                    justify-content: center;
                    align-items: center;
                    height: 30px;
                    background-color: rgba(153, 153, 153, .4);

                    div:nth-child(1) {
                        cursor: pointer;
                    }

                    div:nth-child(2) {
                        cursor: pointer;
                    }

                    div:nth-child(3) {
                        cursor: pointer;
                    }
                }


                .arrow {
                    width: 32px;
                    height: 64px;
                    background-color: rgba(153, 153, 153, .6);
                    cursor: pointer;
                }

                .hide_input {
                    width: 100%;
                    height: 100%;
                    font-size: 14px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #333333;
                    overflow: hidden;
                    text-align: center;

                    img {
                        display: block;
                        margin: auto;
                        width: 12px;
                        height: 20px;
                        // background-color: rgba(153, 153, 153, 1);
                        margin-top: 10px;
                    }
                }

                .dateLists {
                    flex: 1;
                    text-align: center;

                    .dateItem {
                        flex: 1;
                        // border-left: 1px solid rgba(153, 153, 153, .5);
                        font-size: 14px;
                        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                        font-weight: 400;
                        color: #333333;
                    }

                    .dateItem:nth-child(1) {
                        border-left: 0;
                    }

                    .days {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-around;
                        
                        li {
                            list-style-type: none;
                            display: inline-block;
                            // border: 1px solid #999;
                            border-top: 1px solid #999;
                            border-bottom: 1px solid #999;
                            border-left: 1px solid #999;
                            cursor: pointer;
                            width: 14.2%;
                            text-align: center;
                            padding-bottom: 3px;
                            padding-top: 7px;
                            font-size: 14px;
                            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                            font-weight: 400;
                            color: #333333;
                            font-weight: 200;
                            height: 45px;
                            line-height: 45px;
                            background: #FFFFFF;
                            // background: rgba(153, 153, 153, 0.5);
                            border-top: 1px solid #FFFFFF;
                        }
                        li:last-child{
                            border-right: 1px solid #999;
                        }
                        li:nth-child(7){
                            border-right:1px solid #999;
                        }
                        li:nth-child(14){
                            border-right:1px solid #999;
                        }
                        li:nth-child(21){
                            border-right:1px solid #999;
                        }
                        li:nth-child(28){
                            border-right:1px solid #999;
                        }
                    }
                }
            }
        }

        .boxMakingDate2 {
            margin-top: 32px;

            .ipt {
                margin-top: 8px;
                height: 40px;

                ::v-deep .el-date-editor {
                    width: 100%;
                }
            }

            .lefts {
                flex: 1;
            }

            .rights {
                flex: 1;
                margin-left: 28px;
            }
        }

        .cargoWeight {
            margin-top: 32px;

            .ipt {
                margin-top: 8px;
                height: 40px;
            }

            .lefts {
                flex: 1;

                p {
                    font-size: 14px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                }
            }

            .rights {
                flex: 1;
                margin-left: 28px;

                p {
                    font-size: 14px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                }
            }
        }

        .boxTypes {
            margin-top: 32px;

            .box1 {
                flex: 1;

                .ipt {
                    margin-top: 4px;
                }
                p{
                    font-size: 14px;
                }
            }
        }

        .whiteCard {
            margin-top: 32px;

            span:nth-child(2) {
                font-size: 14px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #333333;
                margin-left: 4px;
            }

            .el-radio {
                margin-left: 16px;
                margin-right: 0;
            }
        }

        .withholding {
            margin-top: 36px;

            .withholdingLeft {
                flex: 1;

                .isTitle {
                    font-size: 14px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #333333;
                }

                .smallBox {
                    margin-top: 16px;

                    div {
                        width: 100px;
                        height: 32px;
                        border-radius: 4px 4px 4px 4px;
                        border: 1px solid rgba(51, 51, 51, .5);
                        text-align: center;
                        line-height: 32px;
                        margin-left: 16px;
                        cursor: pointer;
                        font-size: 14px;
                        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                        font-weight: 400;
                        color: #999999;
                    }

                    div:nth-child(1) {
                        margin-left: 0;
                    }

                }
            }

            .withholdingRight {
                flex: 1;

                .isTitle {
                    font-size: 14px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #333333;
                }

                .selestDatas {
                    margin-top: 16px;
                    height: 32px;

                    .selects {
                        height: 100%;
                        height: 32px;
                        flex: 1;

                        ::v-deep .el-date-editor {
                            width: 100%;
                            height: 100%;

                            .el-input__inner {
                                height: 100%;
                            }
                        }
                    }

                    .el-input {
                        height: 32px;

                        ::v-deep .el-input__inner {
                            height: 100%;
                        }
                    }
                }
            }


        }

        .withholdingyard {

            .withholdingyardLeft {
                margin-top: 43px;
                flex: 1;
                display: flex;
                justify-content: flex-end;

                .myYard {
                    width: 100px;
                    height: 32px;
                    border-radius: 4px 4px 4px 4px;
                    border: 1px solid rgba(24, 144, 255, .5);
                    text-align: center;
                    line-height: 32px;
                    font-size: 14px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #1890FF;
                    margin-right: 50px;
                    cursor: pointer;
                }
            }

            .withholdingyardRight {
                flex: 1;
                margin-top: 9px;

                .yardTitle {
                    font-size: 14px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #333333;
                }

                .yardInputBox {
                    width: 100%;
                    height: 32px;
                    margin-top: 16px;

                    .el-input {
                        width: 100%;
                        height: 100%;

                        ::v-deep .el-input__inner {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }

        .buttons {
            margin-top: 11px;
            border-top: 1px solid rgba(43, 43, 43, .5);
            padding-top: 10px;
            justify-content: flex-end;

            .cancelBtn {
                width: 60px;
                height: 32px;
                background: #FFFFFF;
                font-size: 16px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #333333;
                border-radius: 4px 4px 4px 4px;
                text-align: center;
                line-height: 32px;
                border: 1px solid rgba(51, 51, 51, .5);
                cursor: pointer;
            }

            .determineBtn {
                width: 60px;
                height: 32px;
                background: #1890FF;
                border-radius: 4px 4px 4px 4px;
                font-size: 16px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #FFFFFF;
                text-align: center;
                line-height: 32px;
                margin-left: 32px;
                cursor: pointer;
            }
        }
    }

}
</style>